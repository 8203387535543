/*jslint browser: true*/
/*eslint-env browser*/
/*global jQuery*/

jQuery(document).ready(function ($) {
    'use strict';

	$(document).foundation();

    $('#testimonial .owl-carousel').owlCarousel(
		{
			items: 1,
			loop: true,
            nav: true,
            dots: false,
            navText: ['',''],
            /*autoplayTimeout: 8000,*/
			smartSpeed: 1200,
			autoplay: true
		}
	);
	
    $('#logo .owl-carousel').owlCarousel(
		{
			/*items: 6,*/
			loop: true,
            nav: true,
            dots: false,
            navText: ['',''],
            /*autoplayTimeout: 8000,*/
			smartSpeed: 1200,
			margin: 30,
			stagePadding: 1,
			autoplay: true,
			responsive:{
				0:{
					items:2,
					nav:false
				},
				640:{
					items:4,
					nav:false
				},
				1024:{
					items:6,
					nav:true
				}
			}
		}
	);

	$('#slider .owl-carousel').owlCarousel(
		{
			items: 1,
			loop: true,
            nav: true,
            dots: false,
            navText: ['',''],
            /*autoplayTimeout: 8000,*/
			smartSpeed: 1200,
			autoplay: true
		}
	);

    //add data-gallery to post images
    //$( '.single figure.gallery-item .gallery-icon a, .single article .entry-content p > a:has(img), .single article .entry-content p > a:not(.more-link), .single article .entry-content .post_kep a' ).attr( 'data-gallery', '' );
    $('.single figure.gallery-item .gallery-icon a, .single article .entry-content p > a:has(img), .single article .entry-content .post_kep a').attr('data-gallery', '');
    
    $('i.fa.fa-search').on('click', function () {
		$(this).parent().next().toggleClass('show');
	});
    
	// wow init
	//new WOW().init();
	
    // animation when in viewport
	$('.timeline-item:nth-child(odd) .tm-kep').addClass('animated bounceInRight');
	$('.timeline-item:nth-child(even) .tm-kep').addClass('animated bounceInLeft');
	
	if ($(window).width() < 640) {
		$('.timeline-item:nth-child(even) .tm-kep').removeClass('bounceInLeft');
		$('.timeline-item:nth-child(even) .tm-kep').addClass('bounceInRight');
	}
	$(window).resize(function () {
		if ($(window).width() < 640) {
			$('.timeline-item:nth-child(even) .tm-kep').removeClass('bounceInLeft');
			$('.timeline-item:nth-child(even) .tm-kep').addClass('bounceInRight');
		} else {
			$('.timeline-item:nth-child(even) .tm-kep').addClass('bounceInLeft');
			$('.timeline-item:nth-child(even) .tm-kep').removeClass('bounceInRight');
		}
	});
	
    /*var $animation_elements = $('.timeline-item .tm-kep img');
    var $window = $(window);

    function check_if_in_view() {
      var window_height = $window.height();
      var window_top_position = $window.scrollTop();
      var window_bottom_position = (window_top_position + window_height);

      $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
          $element.addClass('wow animated');
        } else {
          $element.removeClass('wow animated');
        }
      });
    }

    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');*/

	// Animate css when in viewport
	/*$('.timeline-item:nth-child(odd) .tm-kep img').addClass('').viewportChecker({
		classToAdd: 'inview animated fadeInRight', // Class to add to the elements when they are visible
		offset: 100, // The offset of the elements (let them appear earlier or later)
		repeat: true, // Add the possibility to remove the class if the elements are not visible
		callbackFunction: function(elem, action){}, // Callback to do after a class was added to an element. Action will return "add" or "remove", depending if the class was added or removed
		scrollHorizontal: false // Set to true if your website scrolls horizontal instead of vertical.
	});	
	$('.timeline-item:nth-child(even) .tm-kep img').addClass('').viewportChecker({
		classToAdd: 'inview animated fadeInLeft', // Class to add to the elements when they are visible
		offset: 100, // The offset of the elements (let them appear earlier or later)
		repeat: true, // Add the possibility to remove the class if the elements are not visible
		callbackFunction: function(elem, action){}, // Callback to do after a class was added to an element. Action will return "add" or "remove", depending if the class was added or removed
		scrollHorizontal: false // Set to true if your website scrolls horizontal instead of vertical.
	});	*/
	
    //box toggle
    $('#mino_tagsag .column.column-block, #tevekeny .column.column-block').click(function () {
        var opened = $(this).children('.descript');
        if (opened.hasClass('open')) {
            $(opened).slideUp('fast').removeClass('open');
        } else {
            $(opened).addClass('open');
            $('.open').slideUp('fast', function () {
                $(this).removeClass('open');
                $(opened).slideDown();
                $(opened).addClass('open');
            });
        }
    });
	
	// csapat bővebben
    $('.nyil').click(function () {
        var opened = $(this).next();
        var shadow = $(this).parent();
        var color = $(this).parent().prev();
        /*if (opened.hasClass('open')) {
            $(opened).slideUp('fast').removeClass('open');
        } else {
            $(opened).addClass('open');
            $('.open').slideUp('fast', function () {
                $(this).removeClass('open');
                $(opened).slideDown();
                $(opened).addClass('open');
            });
        }*/
        if (opened.hasClass('open')) {
            $(opened).slideUp('fast').removeClass('open');
			$(shadow).removeClass('shadow');
			$(color).removeClass('color');
		} else {
            $(opened).addClass('open');
			$(shadow).addClass('shadow');
			$(color).addClass('color');
            $('.open').slideUp('fast', function () {
                $(this).removeClass('open');
				$(this).parent().removeClass('shadow');
				$(this).parent().prev().removeClass('color');
                $(opened).slideDown();
                $(opened).addClass('open');
				$(shadow).addClass('shadow');
				$(color).addClass('color');
            });
        }
        /*if (shadow.hasClass('shadow')) {
            $(shadow).removeClass('shadow');
        } else {
            $(shadow).addClass('shadow');
			$(this).removeClass('shadow');
        }*/
    });
	

	
    /*setTimeout(function() {
		$('#slider > input:first')
		.attr('checked', true)
		.removeAttr('checked')
		.next('input')
		.attr('checked', true)
		.removeAttr('checked')
		.next('input')
		.attr('checked', true)
		.removeAttr('checked')
		}, 4000);*/
	
	/*$('#slide1').attr('checked', true);
	
	setTimeout(function () {
		$('#slide1')
			.removeAttr('checked');
		$('#slide2')
			.attr('checked', true);
	}, 6000);

	setTimeout(function () {
		$('#slide2')
			.removeAttr('checked');
		$('#slide3')
			.attr('checked', true);
	}, 12000);

	setTimeout(function () {
		$('#slide3')
			.removeAttr('checked');
		$('#slide1')
			.attr('checked', true);
	}, 18000);

	setInterval(function () {
		setTimeout(function () {
			$('#slide1')
				.removeAttr('checked');
			$('#slide2')
				.attr('checked', true);
		}, 6000);

		setTimeout(function () {
			$('#slide2')
				.removeAttr('checked');
			$('#slide3')
				.attr('checked', true);
		}, 12000);

		setTimeout(function () {
			$('#slide3')
				.removeAttr('checked');
			$('#slide1')
				.attr('checked', true);
		}, 18000);
	}, 18000);*/
	
	// home slider
	/*$('#slider').owlCarousel(
		{
			animateIn: 'fadeIn',
			items: 1,
			loop: true,
			margin: 0,
			autoplay: true,
			autoplayTimeout: 6000,
			autoplayHoverPause: false
		}
	);*/

	// work slider
	/*$('#work-slider.owl-carousel').owlCarousel(
		{
			items: 1,
			loop: true,
			video: true,
			merge: true,
			lazyLoad: true,
			thumbs: true,
			thumbsPrerendered: true
		}
	);*/

	// about slider
	/*$('#secondary.owl-carousel').owlCarousel(
		{
			items: 1,
			loop: true,
			autoplay: true
		}
	);*/

	// jobform
	/*$('.hire .kep').on('click', function() {
		$('.hire, .jobform').toggleClass('open');
	});
	if( $('#wpcf7-f74-o1 .wpcf7-response-output').hasClass('wpcf7-mail-sent-ok') ){
		$('.hire, .jobform').addClass('open');
	} else if ( $('#wpcf7-f74-o1 .wpcf7-response-output').hasClass('wpcf7-validation-errors') ){
		$('.hire, .jobform').addClass('open');
	} else if( $('#wpcf7-f74-o2 .wpcf7-response-output').hasClass('wpcf7-mail-sent-ok') ){
		$('.hire, .jobform').addClass('open');
	} else if ( $('#wpcf7-f74-o2 .wpcf7-response-output').hasClass('wpcf7-validation-errors') ){
		$('.hire, .jobform').addClass('open');
	} else {
		$('.hire, .jobform').removeClass('open');
	}*/
	
	
	
    // grid főoldal hírek
    /*var $grid = $('.grid').isotope({
        itemSelector: '.grid-item',
        percentPosition: true,
        layoutMode: 'masonry',
        masonry: {
            //columnWidth: '.grid-sizer',
            gutter: 30
        }
    });
    
    $grid.imagesLoaded().progress( function() {
        $grid.isotope('layout');
    });*/
    
	
	// grid works 
    /*var qsRegexWork;
    var $grid_work = $('.grid-work').isotope({
        itemSelector: '.grid-item-work',
        percentPosition: true,
        layoutMode: 'masonry',
        masonry: {
            columnWidth: '.grid-sizer-work',
            gutter: '.gutter-sizer-work'
        },
        filter: function() {
            return qsRegexWork ? $(this).text().match( qsRegexWork ) : true;
        }
    });
 
    $grid_work.imagesLoaded().progress( function() {
        $grid_work.isotope('layout');
    });*/

	// grid filter
    /*$('#work-filter li.menu-item').on( 'click', function() {
        $(this).parent().find('li').removeClass('current-menu-item');
        $(this).addClass('current-menu-item');
        var filterValue = $(this).attr('data-filter');
        $grid_work.isotope({ filter: filterValue });
    });*/

    // grid filter ha url-ben ott a hash
    /*var hashID = "." + window.location.hash.substring(1);
    if(window.location.hash) { 
        $grid_work.isotope({
            itemSelector: '.grid-item-work',
            filter: hashID 
        });        
    } else {
        $grid_work.isotope({
            itemSelector: '.grid-item-work'
        });
    }*/

	
	// grid team 
    var qsRegexTeam;
    var $grid_team = $('.grid-team').isotope({
        itemSelector: '.grid-item-team',
        percentPosition: true,
        layoutMode: 'masonry',
        masonry: {
            columnWidth: '.grid-sizer-team',
            gutter: '.gutter-sizer-team'
        },
        filter: function() {
            return qsRegexTeam ? $(this).text().match( qsRegexTeam ) : true;
        }
    });
 
    $grid_team.imagesLoaded().progress( function() {
        $grid_team.isotope('layout');
    });

	// grid filter
    $('#position-filter li.menu-item').on( 'click', function() {
        $(this).parent().find('li').removeClass('current-menu-item');
        $(this).addClass('current-menu-item');
        var filterValue = $(this).attr('data-filter');
        $grid_team.isotope({ filter: filterValue });
    });

    // grid filter ha url-ben ott a hash
    var hashID = "." + window.location.hash.substring(1);
    if(window.location.hash) { 
        $grid_team.isotope({
            itemSelector: '.grid-item-team',
            filter: hashID 
        });        
    } else {
        $grid_team.isotope({
            itemSelector: '.grid-item-team'
        });
    }
	
	// grid referencia 
    var qsRegexRef;
    var $grid_ref = $('.grid-ref').isotope({
        itemSelector: '.grid-item-ref',
        percentPosition: true,
        layoutMode: 'masonry',
        masonry: {
            columnWidth: '.grid-sizer-ref',
            gutter: '.gutter-sizer-ref'
        },
        filter: function() {
            return qsRegexRef ? $(this).text().match( qsRegexRef ) : true;
        }
    });
 
    $grid_ref.imagesLoaded().progress( function() {
        $grid_ref.isotope('layout');
    });

	// grid filter
    $('#referencia-filter li.menu-item').on( 'click', function() {
        $(this).parent().find('li').removeClass('current-menu-item');
        $(this).addClass('current-menu-item');
        var filterValue = $(this).attr('data-filter');
        $grid_ref.isotope({ filter: filterValue });
    });

    // grid filter ha url-ben ott a hash
    var hashID1 = "." + window.location.hash.substring(1);
    if(window.location.hash) { 
        $grid_ref.isotope({
            itemSelector: '.grid-item-ref',
            filter: hashID1 
        });        
    } else {
        $grid_ref.isotope({
            itemSelector: '.grid-item-ref'
        });
    }
	
	// grid front referencia 
    var qsRegexFrontRef;
    var $grid_frontref = $('.grid-frontref').isotope({
        itemSelector: '.grid-item-frontref',
        percentPosition: true,
        layoutMode: 'masonry',
        masonry: {
            columnWidth: '.grid-sizer-frontref',
            gutter: '.gutter-sizer-frontref'
        },
        filter: function() {
            return qsRegexFrontRef ? $(this).text().match( qsRegexFrontRef ) : true;
        }
    });
 
    $grid_frontref.imagesLoaded().progress( function() {
        $grid_frontref.isotope('layout');
    });


	// search filter
    /*var $quicksearch = $('.quicksearch').keyup( debounce( function() {
        qsRegex = new RegExp( $quicksearch.val(), 'i' ); // 'i' = case insensitive
        $grid_work.isotope();
    }, 200 ) );*/

    // debounce so filtering doesn't happen every millisecond
    /*function debounce( fn, threshold ) {
    var timeout;
        return function debounced() {
            if ( timeout ) {
                clearTimeout( timeout );
            }
            function delayed() {
                fn();
                timeout = null;
            }
            timeout = setTimeout( delayed, threshold || 100 );
        };
    }*/

	// hoveres stilusa legyen a filternek
	/*$('#work-filter li, #position-filter li').on( 'click', function() {
		$(this).addClass('hovered').siblings().removeClass('hovered');
    });*/

	// secondary magassága mint a primary-é
	/*$(window).resize(function () {
		location.reload();
	});
	var height = $('#primary').height();*/
    /*if( $(window).width() > 639) {
        var height = $('body').height();
        if ($('body').height() > $(window).height()) {
            $('#secondary').css('height', height + 160);
        }
    } */
	
	
	// contact form 7 fill the position on click
	$('.accordion-title.select').on( 'click', function() {
		var position = $(this).text()
		//console.log(position);
		$( 'input.apply' ).val( position );
    });
	
});